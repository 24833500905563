<template>
    <b-container>
        <b-tabs card pills align="center">
            <b-tab title="Jeux de rôle" lazy>
                <borrowable-block-list :borrowables="jdrs" type="jdrs" v-if="jdrsLoaded"/>
            </b-tab>
            <b-tab title="Jeux de plateau" lazy active>
                <borrowable-block-list :borrowables="jeux" type="jeux" v-if="jeuxLoaded"/>
            </b-tab>
            <b-tab title="Livres" lazy>
                <borrowable-block-list :borrowables="livres" type="livres" v-if="livresLoaded"/>
            </b-tab>
        </b-tabs>
    </b-container>
</template>

<script>
import {apiPath} from '@/util/http';
import alert     from '@/util/alert';

const BorrowableBlockList = () => import('@/components/BorrowableBlockList');

export default {
    name: 'ListJeuxLivres',
    components: {BorrowableBlockList},
    data: () => ({
        jdrs: [],
        jeux: [],
        livres: [],
        jdrsLoaded: false,
        jeuxLoaded: false,
        livresLoaded: false
    }),
    methods: {
        loadData() {
            alert.loading();
            // Chargement des jeux
            this.axios.get(apiPath('list_jeux_cluji_description'))
                .then(response => this.jeux = response.data)
                // Chargement des disponibilités pour les jeux
                .then(() => this.axios.get(apiPath('jeux_cluji_available_now')))
                .then(response => this.jeux = this.jeux.map(jeu => ({
                    ...jeu,
                    available: response.data.includes(jeu.id)
                })))
                .then(() => this.jeuxLoaded = true)
                .catch(() => this.$toaster.error('Impossible de récupérer la liste des jeux de plateau'))
                // Chargement des livres
                .then(() => this.axios.get(apiPath('list_livres_cluji_description')))
                .then(response => this.livres = response.data)
                // Chargement des disponibilités pour les livres
                .then(() => this.axios.get(apiPath('livres_cluji_available_now')))
                .then(response => this.livres = this.livres.map(livre => ({
                    ...livre,
                    description: [livre.auteur, livre.description].filter(s => s && s.length).join(' - '),
                    available: response.data.includes(livre.id)
                })))
                .then(() => this.livresLoaded = true)
                .catch(() => this.$toaster.error('Impossible de récupérer la liste des livres'))
                // Chargement des JdR
                .then(() => this.axios.get(apiPath('list_jdrs_cluji_description')))
                .then(response => this.jdrs = response.data)
                // Chargement des disponibilités pour les JdR
                .then(() => this.axios.get(apiPath('jdrs_cluji_available_now')))
                .then(response => this.jdrs = this.jdrs.map(jdr => ({
                    ...jdr,
                    available: response.data.includes(jdr.id)
                })))
                .then(() => this.jdrsLoaded = true)
                .catch(() => this.$toaster.error('Impossible de récupérer la liste des jeux de rôle'))
                .finally(alert.stopLoading);
        }
    },
    mounted() {
        this.loadData();
    }
};
</script>